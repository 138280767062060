import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: "/",
    redirect: "/inicio"
  },
  {
    path: "/inicio",
    name: "InicioVue",
    component: () => import('../views/Inicio.vue')
  },
  {
    path: "/facturas/:tipo",
    name: "FacturasControler",
    props: true,
    component: () => import('../views/Facturas.vue'),
    beforeEach: [validacion]
  },
  {
    path: "/resumen",
    name: "ResumenControler",
    component: () => import('../views/Resumen.vue'),
    beforeEach: [validacion]
  },
  {
    path: "/validacion",
    name: "ValidacionConsecutivosController",
    component: () => import('../views/ValidacionConsecutivos.vue'),
    beforeEach: [validacion]
  },
  {
    path: "/empresas",
    name: "EmpresasController",
    component: () => import('../views/Empresas.vue'),
    beforeEach: [validacion]
  },
  {
    path: "/empresa",
    name: "EmpresaController",
    component: () => import('../views/Empresa.vue'),
    beforeEach: [validacion]
  },
  {
    path: "/proveedores",
    name: "ProveedorController",
    component:()=> import('../views/Proveedores.vue'),
    beforeEach: [validacion]
  },
  {
    path: "/soportes",
    name: "SoporteController",
    component:()=> import('../views/Soportes.vue'),
    beforeEach: [validacion]
  },
  {
    path: "/facturas-recibidas",
    name: "FacturasRecibidasController",
    component:()=> import('../views/FacturasRecibidas.vue'),
    beforeEach: [validacion]
  },
  {
    path: "/soportes/:idSoporte",
    name: "DetalleSoporte",
    component: ()=> import('../views/DetalleSoporte.vue'),
    beforeEach: [validacion]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


function validacion(to, from){
  setTimeout(function(){
    //console.log(to)
    var guid = sessionStorage.getItem('Token');
    var empresa = sessionStorage.getItem("empresa");
    
    if (!guid || !empresa) {
      console.log("redirigiendo ...")
      return { name: "InicioVue"}
    }

    return from;
  }, 50);
}


export default router
